<template>
  <div style="height: 800px">
    <div style="width: 100%;height: 900px" id="map"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  import bmapStyle from './bmap-style-dark'


  export default {
    data(){
      return {
        myChart:"",
      }
    },
    created() {
    },
    mounted() {

    },

    methods:{
      update(onlineData,offlineData){
        this.myChart = echarts.init(document.getElementById('map'));
        this.myChart.setOption({
          title: {
            text: '',
            left: 'center',
            top:"15px",
            textStyle:{
              color:"#FFF"
            }
          },
          tooltip : {
            trigger: 'item',
            formatter: (params,ticket)=>{
              let device = params.data
              let status = device.status==1?"在线":"离线"
              return `${device.nickname||""}<br/>设备状态：${status}<br/>设备MAC：${device.mac}<br/>地址：${device.address}`
            }
          },
          bmap: {
            center: [110.114129, 23.550339],
            zoom: 5,
            roam: true,
            mapStyle: bmapStyle
          },
          series : [
            {
              name: '离线设备',
              type: 'scatter',
              coordinateSystem: 'bmap',
              data: offlineData,
              symbolSize: 10,
              encode: {
                value: 2
              },
              label: {
                formatter: '{b}',
                position: 'right',
                show: false
              },
              emphasis: {
                label: {
                  show: true
                }
              },
              itemStyle: {
                color: "#FFFFFF",
                shadowBlur: 2,
                shadowColor: "#000"
              }
            },
            {
              name: '在线设备',
              type: 'effectScatter',
              coordinateSystem: 'bmap',
              data: onlineData,
              symbolSize: 10,
              encode: {
                value: 2
              },
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke',
                scale:3.5,
              },
              hoverAnimation: true,
              label: {
                formatter: '{b}',
                position: 'right',
                show: false
              },
              itemStyle: {
                color: "#27d85a",
                shadowBlur: 10,
                shadowColor: '#333'
              },
              zlevel: 1
            }
          ]
        });
      }
    }
  }
</script>

<style scoped>
  .anchorBL{
    display: none !important;
  }
</style>
